import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Layout, SliceZone } from '../components'


const Container = styled.div`
	margin-top: 3rem;
`

const AboutContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap-reverse;
`

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-right: 4rem;
	@media (max-width: 1024px) {
		min-width: 100%;
		margin-right: 0rem;
  }
`

const MeContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	h3 {
		margin: 2rem;
		font-family: AvenirBlack;
	}
	h5 {
		width: 100%;
		margin-block-end: 0;
  }
	margin-left: 4rem;
	@media (max-width: 599px) {
		align-items: flex-start;
	}
  @media (max-width: 1024px) {
    flex-direction: row;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 2rem;
		margin-left: 0rem;
		h3 {
			margin: 0rem 0rem 1rem 0rem;
		}
		margin-left: 0rem;
  }
`

const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 1024px) {
		width: 100%;
		align-items: flex-start;
		margin-left: 1.5rem;
		h3 {
			width: 100%;
			text-align: left;
		}
		h5 {
			width: 100%;
		}
	}
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	margin-bottom: 1.5rem;
	h5 {
		text-align: left;
	}		
	a {
		width: 100%;
	}
	img {
		margin-right: 1rem;
		height: 12px;
		width: auto;
	}
	@media (max-width: 599px) {
		margin-bottom: 1rem;
		img {
			margin-right: 0.5rem;
		}
	}
`

const HeroImg = styled.img`
	height: ${props => `${props.dimensions.height}px`};
	width: ${props => `${props.dimensions.width}px`};
`

const HeroImgLa = styled(HeroImg)`
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.l}) {
    display: block;
  }
`

const HeroImgT = styled(HeroImg)`
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.s}) and (max-width: 1199px) {
    display: block;
  }
`

const HeroImgM = styled(HeroImg)`
  display: none;
  @media (max-width: 599px) {
    display: block;
  }
`

const TextInnerContainer = styled.div`
	margin: 0rem 0rem 2rem 0rem;
`

class Index extends Component {
  render() {
		const { data: { about: { data: { about_hero_image, about_me, body, email, title, phone } } }} = this.props;
		const { Mobile, Tablet, url, dimensions } = about_hero_image; 
    return (
      <Layout shouldRotate={false}>
				<Container>
					<AboutContainer>
						<TextContainer>
							<TextInnerContainer>
								<h2>Um mig</h2>
								<div dangerouslySetInnerHTML={{__html: about_me.html}}/>
							</TextInnerContainer>
							<TextInnerContainer>
								<h2 style={{marginTop: '4rem', fontFamily: 'AvenirMedium'}}>Menntun</h2>
								<SliceZone allSlices={body}/>
							</TextInnerContainer>
						</TextContainer>
						<MeContainer>
							<HeroImgLa src={url} dimensions={dimensions}/>
							<HeroImgT src={Tablet.url} dimensions={Tablet.dimensions}/>
							<HeroImgM src={Mobile.url} dimensions={Mobile.dimensions}/>
							<InfoContainer>
								<h3>Dóróthea Ármann</h3>
								<Row>
									<img src={'/logos/person.svg'} alt='Person logo'/>
									<h5>{title.text}</h5>
								</Row>
								<Row>
									<img src={'/logos/email.svg'} alt='Email logo'/>
									<a href={`mailto:${email.text}`}>
										<h5>{email.text}</h5>
									</a>
								</Row>
								<Row>
									<img src={'/logos/tel.svg'} alt='Telephone logo'/>
									<a href={`tel:${phone.text}`}>
										<h5>{phone.text}</h5>
									</a>
								</Row>
							</InfoContainer>
						</MeContainer>
					</AboutContainer>
				</Container>
      </Layout>
    )
  }
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    about: PropTypes.shape({
      about_hero_image: PropTypes.shape({
				url: PropTypes.string.isRequired,
				dimensions: PropTypes.shape({
					height: PropTypes.number.isRequired,
					width: PropTypes.number.isRequired
				}),
				Mobile: PropTypes.shape({
					url: PropTypes.string.isRequired,
					dimensions: PropTypes.shape({
						height: PropTypes.number.isRequired,
						width: PropTypes.number.isRequired
					}),
				}),
				Tablet: PropTypes.shape({
					url: PropTypes.string.isRequired,
					dimensions: PropTypes.shape({
						height: PropTypes.number.isRequired,
						width: PropTypes.number.isRequired
					}),
				}),
				about_me: PropTypes.shape({
					text: PropTypes.string.isRequired,
					html: PropTypes.string.isRequired,
				}),
				body: PropTypes.arrayOf(
					PropTypes.shape({
						slice_type: PropTypes.string.isRequired,
						primary: PropTypes.shape({
							start_date: PropTypes.string.isRequired,
							end_date: PropTypes.string.isRequired,
							field_of_study: PropTypes.shape({
								text: PropTypes.string.isRequired
							}),
							university_name: PropTypes.shape({
								text: PropTypes.string.isRequired,
								html: PropTypes.string.isRequired
							})
						})
					}),
				),
				email: PropTypes.shape({
					text: PropTypes.string.isRequired,
					html: PropTypes.string.isRequired
        }),
				title: PropTypes.shape({
					text: PropTypes.string.isRequired,
					html: PropTypes.string.isRequired
        }),
				phone: PropTypes.shape({
					text: PropTypes.string.isRequired,
					html: PropTypes.string.isRequired
				}),
      }),
    }),
  }).isRequired,
}

export const pageQuery = graphql`
  query IndexQuery {
    about: prismicAbout {
			data {
				about_hero_image {
					url
					Mobile {
						url
						dimensions {
							height
							width
						}
					}
					Tablet {
						url
						dimensions {
							height
							width
						}
					}
					dimensions {
						width
						height
					}
				}
				about_me {
					text
					html
				}
				body {
					slice_type
					primary {
						start_date(formatString: "MM.YYYY")
						end_date(formatString: "MM.YYYY")
						field_of_study {
							text
						}
						university_name {
							text
							html
						}
					}
				}
				email {
					text
					html
				}
				title {
					text
					html
				}
				phone {
					text
					html
				}
			}
		}
	}
`
